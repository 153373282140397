import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { PermissionsGuard } from './guards/permissions.guard';
import { ProviderPermissionsGuard } from './provider/guards/provider-permissions.guard';

const routes: Routes = [
    {
        path: 'login',
        pathMatch: 'full',
        loadComponent: () =>
            import('./pages/login/login.component').then(
                (x) => x.LoginComponent
            ),
    },
    {
        path: 'confirm-reset-password',
        pathMatch: 'full',
        loadComponent: () =>
            import(
                './pages/confirm-reset-password/confirm-reset-password.component'
            ).then((x) => x.ConfirmResetPasswordComponent),
    },
    {
        path: '',
        loadComponent: () =>
            import('./pages/account-detail/account-detail.component').then(
                (x) => x.AccountDetailComponent
            ),
        canActivate: [AuthGuard],
        children: [
            {
                path: 'loading-mode-planning',
                loadComponent: () =>
                    import(
                        './pages/loading-mode-planning/loading-mode-planning.component'
                    ).then((x) => x.LoadingModePlanningComponent),
            },
            {
                path: 'individual-planning/:id',
                loadComponent: () =>
                    import(
                        './pages/individual-planning/individual-planning.component'
                    ).then((x) => x.IndividualPlanningComponent),
            },
            {
                path: 'main',
                loadComponent: () =>
                    import('./pages/main/main.component').then(
                        (x) => x.MainComponent
                    ),
                data: {
                    permissions:
                        'UserInterface:Web:Thot:Widget:MainWindow:Display',
                },
                canActivate: [PermissionsGuard],
            },
            {
                path: 'user-detail',
                loadComponent: () =>
                    import('./pages/user-detail/user-detail.component').then(
                        (x) => x.UserDetailComponent
                    ),
                data: {
                    permissions:
                        'UserInferface:Web:Thot:Widget:UserDetailMainWindow:Display',
                },
                canActivate: [PermissionsGuard],
            },
            {
                path: 'infos',
                loadComponent: () =>
                    import(
                        './pages/account-infos/account-infos.component'
                    ).then((x) => x.AccountInfosComponent),
                data: {
                    permissions:
                        'UserInterface:Web:Thot:Widget:CompanyMainWindow:Display',
                },
                canActivate: [PermissionsGuard],
            },
            {
                path: 'providers',
                children: [
                    {
                        path: 'wrapper',
                        loadComponent: () =>
                            import(
                                './pages/provider-wrapper/provider-wrapper.component'
                            ).then((x) => x.ProviderWrapperComponent),
                        children: [
                            {
                                path: 'list',
                                loadComponent: () =>
                                    import(
                                        './pages/provider-listing/provider-listing.component'
                                    ).then((x) => x.ProviderListingComponent),
                                data: {
                                    permissions:
                                        'UserInterface:Web:Thot:Widget:ProviderMainWindow:Display',
                                },
                                canActivate: [PermissionsGuard],
                            },
                            {
                                path: 'contacts',
                                loadComponent: () =>
                                    import(
                                        './pages/provider-contacts/provider-contacts.component'
                                    ).then((x) => x.ProviderContactsComponent),
                                data: {
                                    permissions:
                                        'UserInterface:Web:Thot:Widget:ProviderContactMainWindow:Display',
                                },
                                canActivate: [PermissionsGuard],
                            },
                            {
                                path: 'groups',
                                loadComponent: () =>
                                    import(
                                        './pages/provider-group-listing/provider-group-listing.component'
                                    ).then(
                                        (x) => x.ProviderGroupListingComponent
                                    ),
                                data: {
                                    permissions:
                                        'UserInterface:Web:Thot:Widget:ProviderGroupMainWindow:Display',
                                },
                                canActivate: [PermissionsGuard],
                            },
                        ],
                    },
                    {
                        path: ':id',
                        loadComponent: () =>
                            import(
                                './pages/provider-detail-wrapper/provider-detail-wrapper.component'
                            ).then((x) => x.ProviderDetailWrapperComponent),
                        children: [
                            {
                                path: 'infos',
                                loadComponent: () =>
                                    import(
                                        './pages/provider-detail-infos/provider-detail-infos.component'
                                    ).then(
                                        (x) => x.ProviderDetailInfosComponent
                                    ),
                            },
                            {
                                path: 'contacts',
                                loadComponent: () =>
                                    import(
                                        './pages/provider-contacts/provider-contacts.component'
                                    ).then((x) => x.ProviderContactsComponent),
                            },
                            {
                                path: 'workshop',
                                loadComponent: () =>
                                    import(
                                        './pages/workshop/workshop.component'
                                    ).then((x) => x.WorkshopComponent),
                            },
                        ],
                    },
                ],
            },
            {
                path: 'storage',
                children: [
                    {
                        path: 'wrapper',
                        loadComponent: () =>
                            import(
                                './pages/storage-sites-wrapper/storage-sites-wrapper.component'
                            ).then((x) => x.StorageSitesWrapperComponent),
                        data: {
                            permissions:
                                'UserInterface:Web:Thot:Widget:StockMainWindow:Display',
                        },
                        children: [
                            {
                                path: 'list',
                                loadComponent: () =>
                                    import(
                                        './pages/storage-sites-listing/storage-sites-listing.component'
                                    ).then(
                                        (x) => x.StorageSitesListingComponent
                                    ),
                                data: {
                                    permissions:
                                        'UserInterface:Web:Thot:Widget:StockMainWindow:Display',
                                },
                                canActivate: [PermissionsGuard],
                            },
                            {
                                path: 'locations',
                                loadComponent: () =>
                                    import(
                                        './pages/storage-location-listing/storage-location-listing.component'
                                    ).then(
                                        (x) => x.StorageLocationListingComponent
                                    ),
                                data: {
                                    permissions:
                                        'UserInterface:Web:Thot:Widget:StockLocationMainWindow:Display',
                                },
                                canActivate: [PermissionsGuard],
                            },
                        ],
                    },
                ],
            },
            {
                path: 'items',
                children: [
                    {
                        path: 'wrapper',
                        loadComponent: () =>
                            import(
                                './pages/items-wrapper/items-wrapper.component'
                            ).then((x) => x.ItemsWrapperComponent),
                        data: {
                            permissions:
                                'UserInterface:Web:Demeter:Widget:ItemMainWindow:Display',
                        },
                        children: [
                            {
                                path: 'list',
                                loadComponent: () =>
                                    import(
                                        './pages/items-listing/items-listing.component'
                                    ).then((x) => x.ItemsListingComponent),
                                data: {
                                    permissions:
                                        'UserInterface:Web:Demeter:Widget:ItemMainWindow:Display',
                                },
                                canActivate: [PermissionsGuard],
                            },
                            {
                                path: 'item-account-provider-list',
                                loadComponent: () =>
                                    import(
                                        './pages/item-account-provider-listing/item-account-provider-listing.component'
                                    ).then(
                                        (x) =>
                                            x.ItemAccountProviderListingComponent
                                    ),
                            },
                        ],
                    },
                    {
                        path: ':id',
                        loadComponent: () =>
                            import(
                                './pages/item-detail-wrapper/item-detail-wrapper.component'
                            ).then((x) => x.ItemDetailWrapperComponent),
                        data: {
                            permissions:
                                'UserInterface:Web:Demeter:Widget:ItemDetailMainWindow:Display',
                        },
                        canActivate: [PermissionsGuard],
                        children: [
                            {
                                path: 'infos',
                                loadComponent: () =>
                                    import(
                                        './pages/item-detail/item-detail.component'
                                    ).then((x) => x.ItemDetailComponent),
                                data: {
                                    permissions:
                                        'UserInterface:Web:Demeter:Widget:ItemDetailMainWindow:Display',
                                },
                                canActivate: [PermissionsGuard],
                            },
                            {
                                path: 'stocks',
                                loadComponent: () =>
                                    import(
                                        './pages/item-detail-stocks/item-detail-stocks.component'
                                    ).then((x) => x.ItemDetailStocksComponent),
                                data: {
                                    permissions:
                                        'UserInterface:Web:Demeter:Widget:ItemStocksMainWindow:Display',
                                },
                                canActivate: [PermissionsGuard],
                            },
                            {
                                path: 'item-movement',
                                loadComponent: () =>
                                    import(
                                        './pages/item-movement/item-movement.component'
                                    ).then((x) => x.ItemMovementComponent),
                                data: {
                                    permissions:
                                        'UserInterface:Web:Demeter:Widget:ItemMovementMainWindow:Display',
                                },
                                canActivate: [PermissionsGuard],
                            },
                            {
                                path: 'item-account-provider-list',
                                loadComponent: () =>
                                    import(
                                        './pages/item-account-provider-listing/item-account-provider-listing.component'
                                    ).then(
                                        (x) =>
                                            x.ItemAccountProviderListingComponent
                                    ),
                            },
                        ],
                    },
                ],
            },
            {
                path: 'item-movement',
                loadComponent: () =>
                    import(
                        './pages/item-movement/item-movement.component'
                    ).then((x) => x.ItemMovementComponent),
                data: {
                    permissions:
                        'UserInterface:Web:Demeter:Widget:ItemMovementMainWindow:Display',
                },
                canActivate: [PermissionsGuard],
            },
            {
                path: 'notifications',
                loadComponent: () =>
                    import(
                        './pages/notification-listing/notification-listing.component'
                    ).then((x) => x.NotificationListingComponent),
            },
            {
                path: 'preferences',
                loadComponent: () =>
                    import('./pages/preferences/preferences.component').then(
                        (x) => x.PreferencesComponent
                    ),
                data: {
                    permissions:
                        'UserInterface:Web:Thot:Widget:PreferencesMainWindow:Display',
                },
                canActivate: [PermissionsGuard],
            },
            {
                path: 'customers',
                data: {
                    permissions:
                        'UserInterface:Web:Hestia:Widget:CustomerMainWindow:Display',
                },
                canActivate: [PermissionsGuard],
                children: [
                    {
                        path: 'list',
                        loadComponent: () =>
                            import(
                                './pages/customer-listing/customer-listing.component'
                            ).then((x) => x.CustomerListingComponent),
                        data: {
                            permissions:
                                'UserInterface:Web:Hestia:Widget:CustomerMainWindow:Display',
                        },
                        canActivate: [PermissionsGuard],
                    },
                    {
                        path: ':id',
                        loadComponent: () =>
                            import(
                                './pages/customer-detail/customer-detail.component'
                            ).then((x) => x.CustomerDetailComponent),
                    },
                ],
            },
            {
                path: 'agents',
                data: {
                    permissions:
                        'UserInterface:Web:Chronos:Widget:AgentMainWindow:Display',
                },
                canActivate: [PermissionsGuard],
                children: [
                    {
                        path: 'wrapper',
                        loadComponent: () =>
                            import(
                                './pages/agent-wrapper/agent-wrapper.component'
                            ).then((x) => x.AgentWrapperComponent),
                        data: {
                            permissions:
                                'UserInterface:Web:Poseidon:Widget:VehicleMainWindow:Display',
                        },
                        canActivate: [PermissionsGuard],
                        children: [
                            {
                                path: 'list',
                                loadComponent: () =>
                                    import(
                                        './pages/agent-listing/agent-listing.component'
                                    ).then((x) => x.AgentListingComponent),
                                data: {
                                    permissions:
                                        'UserInterface:Web:Chronos:Widget:AgentMainWindow:Display',
                                },
                                canActivate: [PermissionsGuard],
                            },
                            {
                                path: 'groups',
                                loadComponent: () =>
                                    import(
                                        './pages/agent-group-listing/agent-group-listing.component'
                                    ).then((x) => x.AgentGroupListingComponent),
                            },
                        ],
                    },
                    {
                        path: 'presence-check',
                        loadComponent: () =>
                            import(
                                './pages/presence-check/presence-check.component'
                            ).then((x) => x.PresenceCheckComponent),
                        data: {
                            permissions:
                                'UserInterface:Web:Chronos:Widget:PresenceCheckMainWindow:Display',
                        },
                        canActivate: [PermissionsGuard],
                    },
                    {
                        path: 'global-planning',
                        loadComponent: () =>
                            import(
                                './pages/global-planning/global-planning.component'
                            ).then((x) => x.GlobalPlanningComponent),
                        data: {
                            permissions:
                                'UserInterface:Web:Chronos:Widget:GlobalPresence:Display',
                        },
                        canActivate: [PermissionsGuard],
                    },
                    // {
                    //     path: 'activity-report',
                    //     loadComponent: () =>
                    //         import(
                    //             './pages/agent-activity-report-listing/agent-activity-report-listing.component'
                    //         ).then(
                    //             (x) => x.AgentActivityReportListingComponent
                    //         ),
                    //     data: {
                    //         permissions:
                    //             'UserInterface:Web:Chronos:Widget:DriverActivityMainWindow:Display',
                    //     },
                    //     canActivate: [PermissionsGuard],
                    // },
                    {
                        path: 'activity-report/anomaly/:id',
                        loadComponent: () =>
                            import(
                                './pages/agent-anomaly-report-detail/agent-anomaly-report-detail.component'
                            ).then((x) => x.AgentAnomalyReportDetailComponent),
                    },
                    {
                        path: 'activity-report/:id',
                        loadComponent: () =>
                            import(
                                './pages/agent-activity-report-detail/agent-activity-report-detail.component'
                            ).then((x) => x.AgentActivityReportDetailComponent),
                    },
                    {
                        path: ':id',
                        loadComponent: () =>
                            import(
                                './pages/agent-detail-wrapper/agent-detail-wrapper.component'
                            ).then((x) => x.AgentDetailWrapperComponent),
                        children: [
                            {
                                path: 'general-informations',
                                loadComponent: () =>
                                    import(
                                        './pages/agent-detail/agent-detail.component'
                                    ).then((x) => x.AgentDetailComponent),
                            },
                        ],
                    },
                ],
            },
            {
                path: 'check-reports',
                data: {
                    permissions:
                        'UserInterface:Web:Poseidon:Widget:CheckReportMainWindow:Display',
                },
                canActivate: [PermissionsGuard],
                children: [
                    {
                        path: 'list',
                        loadComponent: () =>
                            import(
                                './pages/check-report-all-listing/check-report-all-listing.component'
                            ).then((x) => x.CheckReportAllListingComponent),
                        data: {
                            permissions:
                                'UserInterface:Web:Poseidon:Widget:CheckReportMainWindow:Display',
                        },
                        canActivate: [PermissionsGuard],
                    },
                    {
                        path: ':idCr',
                        loadComponent: () =>
                            import(
                                './pages/check-report-detail/check-report-detail.component'
                            ).then((x) => x.CheckReportDetailComponent),
                    },
                ],
            },
            {
                path: 'maintenance-request',
                children: [
                    {
                        path: 'board',
                        loadComponent: () =>
                            import(
                                './pages/maintenance-request-board/maintenance-request-board.component'
                            ).then((x) => x.MaintenanceRequestComponent),
                        data: {
                            permissions:
                                'UserInterface:Web:Poseidon:Widget:MaintenanceRequestBoardMainWindow:Display',
                        },
                        canActivate: [PermissionsGuard],
                    },
                    {
                        path: 'list',
                        loadComponent: () =>
                            import(
                                './pages/maintenance-request-listing/maintenance-request-listing.component'
                            ).then((x) => x.MaintenanceRequestListingComponent),
                        data: {
                            permissions:
                                'UserInterface:Web:Poseidon:Widget:MaintenanceRequestHistoryMainWindow:Display',
                        },
                        canActivate: [PermissionsGuard],
                    },
                    {
                        path: 'calendar',
                        loadComponent: () =>
                            import(
                                './pages/maintenance-request-calendar/maintenance-request-calendar.component'
                            ).then(
                                (x) => x.MaintenanceRequestCalendarComponent
                            ),
                        data: {
                            permissions:
                                'UserInterface:Web:Poseidon:Widget:MaintenanceRequestBoardMainWindow:Display',
                        },
                        canActivate: [PermissionsGuard],
                    },
                    {
                        path: 'funnel',
                        loadComponent: () =>
                            import(
                                './pages/maintenance-request-funnel/maintenance-request-funnel.component'
                            ).then((x) => x.MaintenanceRequestFunnelComponent),
                        data: {
                            permissions:
                                'UserInterface:Web:Poseidon:Widget:MaintenanceRequestFunnelMainWindow:Display',
                        },
                        canActivate: [PermissionsGuard],
                    },
                ],
            },
            {
                path: 'transport-order',
                children: [
                    {
                        path: 'list',
                        loadComponent: () =>
                            import(
                                './pages/transport-order-listing/transport-order-listing.component'
                            ).then((x) => x.TransportOrderListingComponent),
                        data: {
                            permissions:
                                'UserInterface:Web:Poseidon:Widget:TransportOrderMainWindow:Display',
                        },
                        canActivate: [PermissionsGuard],
                    },
                    {
                        path: 'board-ot',
                        loadComponent: () =>
                            import(
                                './pages/transport-order-board/transport-order-board.component'
                            ).then((x) => x.TransportOrderBoardComponent),
                        data: {
                            permissions:
                                'UserInterface:Web:Poseidon:Widget:TransportOrderBoardMainWindow:Display',
                        },
                        canActivate: [PermissionsGuard],
                    },
                    {
                        path: 'calendar',
                        loadComponent: () =>
                            import(
                                './pages/transport-order-calendar/transport-order-calendar.component'
                            ).then((x) => x.TransportOrderCalendarComponent),
                        data: {
                            permissions:
                                'UserInterface:Web:Poseidon:Widget:TransportOrderCalendarMainWindow:Display',
                        },
                        canActivate: [PermissionsGuard],
                    },
                    {
                        path: 'funnel',
                        loadComponent: () =>
                            import(
                                './pages/transport-order-funnel/transport-order-funnel.component'
                            ).then((x) => x.TransportOrderFunnelComponent),
                        data: {
                            permissions:
                                'UserInterface:Web:Poseidon:Widget:TransportOrderFunnelMainWindow:Display',
                        },
                        canActivate: [PermissionsGuard],
                    },
                ],
            },
            // TODO PERMISSIONS PARAMS ???
            {
                path: 'params',
                data: {
                    permissions:
                        'UserInterface:Web:Thot:Widget:MainWindow:Display',
                },
                canActivate: [PermissionsGuard],
                children: [
                    {
                        path: 'wrapper',
                        loadComponent: () =>
                            import(
                                './pages/account-wrapper/account-wrapper.component'
                            ).then((x) => x.AccountWrapperComponent),
                        data: {
                            permissions:
                                'UserInterface:Web:Thot:Widget:MainWindow:Display',
                        },
                        canActivate: [PermissionsGuard],
                        children: [
                            {
                                path: 'tire-brand-list',
                                loadComponent: () =>
                                    import(
                                        './pages/tire-brand-listing/tire-brand-listing.component'
                                    ).then((x) => x.TireBrandListingComponent),
                                data: {
                                    permissions:
                                        'UserInterface:Web:Poseidon:Widget:TireBrandMainWindow:Display',
                                },
                            },
                            {
                                path: 'vehicle-type-list',
                                loadComponent: () =>
                                    import(
                                        './pages/vehicle-type-listing/vehicle-type-listing.component'
                                    ).then(
                                        (x) => x.VehicleTypeListingComponent
                                    ),
                                data: {
                                    permissions:
                                        'UserInterface:Web:Poseidon:Widget:VehicleSubtypesMainWindow:Display',
                                },
                            },
                            {
                                path: 'analytic',
                                loadComponent: () =>
                                    import(
                                        './pages/fleet-analytic/fleet-analytic.component'
                                    ).then((x) => x.FleetAnalyticComponent),
                            },
                            {
                                path: 'typology',
                                loadComponent: () =>
                                    import(
                                        './pages/typology/typology.component'
                                    ).then((x) => x.TypologyComponent),
                            },
                            {
                                path: 'item-families-list',
                                loadComponent: () =>
                                    import(
                                        './pages/item-families-listing/item-families-listing.component'
                                    ).then(
                                        (x) => x.ItemFamiliesListingComponent
                                    ),
                            },
                        ],
                    },
                ],
            },
            {
                path: 'vehicles',
                data: {
                    permissions:
                        'UserInterface:Web:Poseidon:Widget:VehicleMainWindow:Display',
                },
                canActivate: [PermissionsGuard],
                children: [
                    {
                        path: 'wrapper',
                        loadComponent: () =>
                            import(
                                './pages/fleet-management-wrapper/fleet-management-wrapper.component'
                            ).then((x) => x.FleetManagementWrapperComponent),
                        data: {
                            permissions:
                                'UserInterface:Web:Poseidon:Widget:VehicleMainWindow:Display',
                        },
                        canActivate: [PermissionsGuard],
                        children: [
                            {
                                path: 'list',
                                loadComponent: () =>
                                    import(
                                        './pages/fleet-management-listing/fleet-management-listing.component'
                                    ).then(
                                        (x) => x.FleetManagementListingComponent
                                    ),
                                data: {
                                    permissions:
                                        'UserInterface:Web:Poseidon:Widget:VehicleMainWindow:Display',
                                },
                                canActivate: [PermissionsGuard],
                            },
                            {
                                path: 'generic-maintenance-plan',
                                loadComponent: () =>
                                    import(
                                        './pages/fleet-management-maintenance-plan-generic/fleet-management-maintenance-plan-generic.component'
                                    ).then(
                                        (x) => x.GenericMaintenancePlanComponent
                                    ),
                                data: {
                                    permissions:
                                        'UserInterface:Web:Poseidon:Widget:PMSdeadlineMainWindow:Display',
                                },
                                canActivate: [PermissionsGuard],
                            },
                            {
                                path: 'generic-maintenance-plan/:id',
                                loadComponent: () =>
                                    import(
                                        './pages/fleet-management-maintenance-plan/fleet-management-maintenance-plan.component'
                                    ).then(
                                        (x) =>
                                            x.FleetManagementMaintenancePlanComponent
                                    ),
                            },
                            {
                                path: 'statements',
                                loadComponent: () =>
                                    import(
                                        './pages/fleet-statement-list/fleet-statement-list.component'
                                    ).then(
                                        (x) => x.FleetStatementListComponent
                                    ),
                            },
                            {
                                path: 'tire-brand-list',
                                loadComponent: () =>
                                    import(
                                        './pages/tire-brand-listing/tire-brand-listing.component'
                                    ).then((x) => x.TireBrandListingComponent),
                                data: {
                                    permissions:
                                        'UserInterface:Web:Poseidon:Widget:TireBrandMainWindow:Display',
                                },
                            },
                            {
                                path: 'analytic',
                                loadComponent: () =>
                                    import(
                                        './pages/fleet-analytic/fleet-analytic.component'
                                    ).then((x) => x.FleetAnalyticComponent),
                            },
                            {
                                path: 'typology',
                                loadComponent: () =>
                                    import(
                                        './pages/typology/typology.component'
                                    ).then((x) => x.TypologyComponent),
                            },
                            {
                                path: 'groups',
                                loadComponent: () =>
                                    import(
                                        './pages/fleet-group-listing/fleet-group-listing.component'
                                    ).then((x) => x.FleetGroupListingComponent),
                            },
                        ],
                    },
                    {
                        path: 'global-maintenance-plan-elements',
                        loadComponent: () =>
                            import(
                                './pages/global-maintenance-plan-elements/global-maintenance-plan-elements.component'
                            ).then(
                                (x) => x.GlobalMaintenancePlanElementsComponent
                            ),
                    },
                    {
                        path: 'agenda-maintenance-plan-elements',
                        loadComponent: () =>
                            import(
                                './pages/maintenance-plan-agenda/maintenance-plan-agenda.component'
                            ).then((x) => x.MaintenancePlanAgendaComponent),
                    },
                    {
                        path: 'global-cost-wrapper',
                        loadComponent: () =>
                            import(
                                './pages/global-cost-wrapper/global-cost-wrapper.component'
                            ).then((x) => x.GlobalCostWrapperComponent),
                        data: {
                            permissions:
                                'UserInterface:Web:Poseidon:Widget:GlobalCostMainWindow:Display',
                        },
                        canActivate: [PermissionsGuard],
                        children: [
                            {
                                path: 'global-cost-list',
                                loadComponent: () =>
                                    import(
                                        './pages/global-cost-listing/global-cost-listing.component'
                                    ).then((x) => x.GlobalCostListingComponent),
                                data: {
                                    permissions:
                                        'UserInterface:Web:Poseidon:Widget:GlobalCostMainWindow:Display',
                                },
                                canActivate: [PermissionsGuard],
                            },
                            {
                                path: 'fuel-cost-list',
                                loadComponent: () =>
                                    import(
                                        './pages/fuel-cost-listing/fuel-cost-listing.component'
                                    ).then((x) => x.FuelCostListingComponent),
                                data: {
                                    permissions:
                                        'UserInterface:Web:Poseidon:Widget:FuelCostMainWindow:Display',
                                },
                                canActivate: [PermissionsGuard],
                            },
                        ],
                    },
                    {
                        path: 'vehicle-availability',
                        loadComponent: () =>
                            import(
                                './pages/vehicle-availability/vehicle-availability.component'
                            ).then((x) => x.VehicleAvailabilityComponent),
                        data: {
                            permissions:
                                'UserInterface:Web:Poseidon:Widget:VehicleAvailabilityMainWindow:Display',
                        },
                        canActivate: [PermissionsGuard],
                    },
                    {
                        path: 'fleet-map',
                        loadComponent: () =>
                            import(
                                './pages/fleet-map/fleet-map.component'
                            ).then((x) => x.FleetMapComponent),
                    },
                    {
                        path: 'real-time-fleet',
                        loadComponent: () =>
                            import(
                                './pages/real-time-fleet/real-time-fleet.component'
                            ).then((x) => x.RealTimeFleetComponent),
                        data: {
                            permissions:
                                'UserInterface:Web:Poseidon:Widget:RealTimeVehiclePositionsMainWindow:Display',
                        },
                        canActivate: [PermissionsGuard],
                    },
                    {
                        path: 'fleet-positions-historic',
                        loadComponent: () =>
                            import(
                                './pages/fleet-positions-historic/fleet-positions-historic.component'
                            ).then((x) => x.FleetPositionsHistoricComponent),
                        data: {
                            permissions:
                                'UserInterface:Web:Poseidon:Widget:HistoryVehiclePositionsMainWindow:Display',
                        },
                        canActivate: [PermissionsGuard],
                    },
                    {
                        path: ':id',
                        loadComponent: () =>
                            import(
                                './pages/fleet-management-detail/fleet-management-detail.component'
                            ).then((x) => x.FleetManagementDetailComponent),
                        children: [
                            {
                                path: 'general-informations',
                                loadComponent: () =>
                                    import(
                                        './pages/fleet-management-general-informations/fleet-management-general-informations.component'
                                    ).then(
                                        (x) =>
                                            x.FleetManagementGeneralInformationsComponent
                                    ),
                            },
                            {
                                path: 'maintenance-plan',
                                loadComponent: () =>
                                    import(
                                        './pages/fleet-management-maintenance-plan/fleet-management-maintenance-plan.component'
                                    ).then(
                                        (x) =>
                                            x.FleetManagementMaintenancePlanComponent
                                    ),
                            },
                            {
                                path: 'statement-list',
                                loadComponent: () =>
                                    import(
                                        './pages/fleet-statement-list/fleet-statement-list.component'
                                    ).then(
                                        (x) => x.FleetStatementListComponent
                                    ),
                            },
                            {
                                path: 'health-record',
                                loadComponent: () =>
                                    import(
                                        './pages/vehicle-health-record/vehicle-health-record.component'
                                    ).then(
                                        (x) => x.VehicleHealthRecordComponent
                                    ),
                            },
                            {
                                path: 'axle-list',
                                loadComponent: () =>
                                    import(
                                        './pages/fleet-management-axle/fleet-management-axle.component'
                                    ).then(
                                        (x) => x.FleetManagementAxleComponent
                                    ),
                                data: {
                                    permissions:
                                        'UserInterface:Web:Poseidon:Widget:VehicleAxleMainWindow:Display',
                                },
                            },
                        ],
                    },
                ],
            },
            {
                path: 'tours',
                data: {
                    permissions:
                        'UserInterface:Web:Zeus:Widget:TourMainWindow:Display',
                },
                canActivate: [PermissionsGuard],
                children: [
                    {
                        path: 'list',
                        loadComponent: () =>
                            import(
                                './pages/tour-listing/tour-listing.component'
                            ).then((x) => x.TourListingComponent),
                        data: {
                            permissions:
                                'UserInterface:Web:Zeus:Widget:TourMainWindow:Display',
                        },
                        canActivate: [PermissionsGuard],
                    },
                    {
                        path: 'multiple-creation',
                        loadComponent: () =>
                            import(
                                './pages/multiple-tour-entry/multiple-tour-entry.component'
                            ).then((x) => x.MultipleTourEntryComponent),
                        data: {
                            permissions:
                                'UserInterface:Web:Zeus:Widget:MultipleToursCreationMainWindow:Display',
                        },
                        canActivate: [PermissionsGuard],
                    },
                    {
                        path: 'creation',
                        loadComponent: () =>
                            import(
                                './pages/edit-tours/edit-tours.component'
                            ).then((x) => x.EditToursComponent),
                        data: {
                            permissions:
                                'UserInterface:Web:Zeus:Widget:TourCreationMainWindow:Display',
                        },
                        canActivate: [PermissionsGuard],
                    },
                    {
                        path: 'map',
                        loadComponent: () =>
                            import('./pages/tour-map/tour-map.component').then(
                                (x) => x.TourMapComponent
                            ),
                    },
                    {
                        path: 'assigment',
                        loadComponent: () =>
                            import(
                                './pages/tour-assignment/tour-assignment.component'
                            ).then((x) => x.TourAssignmentComponent),
                        data: {
                            permissions:
                                'UserInterface:Web:Zeus:Widget:TourAssignmentMainWindow:Display',
                        },
                        canActivate: [PermissionsGuard],
                    },
                    {
                        path: 'typology',
                        loadComponent: () =>
                            import('./pages/typology/typology.component').then(
                                (x) => x.TypologyComponent
                            ),
                        data: {
                            permissions:
                                'UserInterface:Web:Poseidon:Widget:VehicleTypologyMainWindow:Display',
                        },
                        canActivate: [PermissionsGuard],
                    },
                    // {
                    //     path: 'panel-control',
                    //     loadComponent: () =>
                    //         import(
                    //             './pages/panel-control-tour/panel-control-tour.component'
                    //         ).then((x) => x.PanelControlTourComponent),
                    //     data: {
                    //         permissions:
                    //             'UserInterface:Web:Zeus:Widget:TourPanelControlMainWindow:Display',
                    //     },
                    //     canActivate: [PermissionsGuard],
                    // },
                    {
                        path: 'static',
                        loadComponent: () =>
                            import(
                                './pages/static-tour-listing/static-tour-listing.component'
                            ).then((x) => x.StaticTourListingComponent),
                        data: {
                            permissions:
                                'UserInterface:Web:Zeus:Widget:StaticTourMainWindow:Display',
                        },
                        canActivate: [PermissionsGuard],
                    },
                    {
                        path: 'static/:id',
                        loadComponent: () =>
                            import(
                                './pages/static-tour-detail/static-tour-detail.component'
                            ).then((x) => x.StaticTourDetailComponent),
                    },
                    {
                        path: ':id',
                        children: [
                            {
                                path: 'incidents',
                                loadComponent: () =>
                                    import(
                                        './pages/incident-listing/incident-listing.component'
                                    ).then((x) => x.IncidentListingComponent),
                            },
                            {
                                path: 'check-reports',
                                children: [
                                    {
                                        path: ':idCr',
                                        loadComponent: () =>
                                            import(
                                                './pages/check-report-detail/check-report-detail.component'
                                            ).then(
                                                (x) =>
                                                    x.CheckReportDetailComponent
                                            ),
                                    },
                                ],
                            },
                            {
                                path: 'check-documents',
                                loadComponent: () =>
                                    import(
                                        './pages/check-document-listing/check-document-listing.component'
                                    ).then(
                                        (x) => x.CheckDocumentListingComponent
                                    ),
                            },
                        ],
                    },
                ],
            },
            {
                path: 'physical-support',
                children: [
                    {
                        path: 'wrapper',
                        loadComponent: () =>
                            import(
                                './pages/physical-support-wrapper/physical-support-wrapper.component'
                            ).then((x) => x.PhysicalSupportWrapperComponent),
                        data: {
                            permissions:
                                'UserInterface:Web:Demeter:Widget:PhysicalSupportMainWindow:Display',
                        },
                        canActivate: [PermissionsGuard],
                        children: [
                            {
                                path: 'list',
                                loadComponent: () =>
                                    import(
                                        './pages/physical-support-listing/physical-support-listing.component'
                                    ).then(
                                        (x) => x.PhysicalSupportListingComponent
                                    ),
                                data: {
                                    permissions:
                                        'UserInterface:Web:Demeter:Widget:PhysicalSupportMainWindow:Display',
                                },
                                canActivate: [PermissionsGuard],
                            },
                        ],
                    },
                    {
                        path: ':id',
                        loadComponent: () =>
                            import(
                                './pages/physical-support-detail-wrapper/physical-support-detail-wrapper.component'
                            ).then(
                                (x) => x.PhysicalSupportDetailWrapperComponent
                            ),
                        children: [
                            {
                                path: 'infos',
                                loadComponent: () =>
                                    import(
                                        './pages/physical-support-detail-infos/physical-support-detail-infos.component'
                                    ).then(
                                        (x) =>
                                            x.PhysicalSupportDetailInfosComponent
                                    ),
                            },
                            {
                                path: 'numbered',
                                loadComponent: () =>
                                    import(
                                        './pages/physical-support-detail-numbered/physical-support-detail-numbered.component'
                                    ).then(
                                        (x) =>
                                            x.PhysicalSupportDetailNumberedComponent
                                    ),
                            },
                            {
                                path: 'unnumbered',
                                loadComponent: () =>
                                    import(
                                        './pages/physical-support-detail-unnumbered/physical-support-detail-unnumbered.component'
                                    ).then(
                                        (x) =>
                                            x.PhysicalSupportDetailUnnumberedComponent
                                    ),
                            },
                        ],
                    },
                ],
            },
            {
                path: 'support-balance',
                data: {
                    permissions:
                        'UserInterface:Web:Demeter:Widget:SupportBalancesMainWindow:Display',
                },
                canActivate: [PermissionsGuard],
                children: [
                    {
                        path: 'list',
                        loadComponent: () =>
                            import(
                                './pages/support-balance-listing/support-balance-listing.component'
                            ).then((x) => x.SupportBalanceListingComponent),
                        data: {
                            permissions:
                                'UserInterface:Web:Demeter:Widget:SupportBalancesMainWindow:Display',
                        },
                        canActivate: [PermissionsGuard],
                    },
                ],
            },
            {
                path: 'movement-support',
                data: {
                    permissions:
                        'UserInterface:Web:Demeter:Widget:SupportMovementMainWindow:Display',
                },
                canActivate: [PermissionsGuard],
                children: [
                    {
                        path: 'list',
                        loadComponent: () =>
                            import(
                                './pages/support-movement-listing/support-movement-listing.component'
                            ).then((x) => x.SupportMovementListingComponent),
                        data: {
                            permissions:
                                'UserInterface:Web:Demeter:Widget:SupportMovementMainWindow:Display',
                        },
                        canActivate: [PermissionsGuard],
                    },
                ],
            },
            {
                path: 'provider-main',
                loadComponent: () =>
                    import(
                        './provider/pages/provider-main/provider-main.component'
                    ).then((x) => x.ProviderMainComponent),
                data: {
                    permissions:
                        'UserInterface:Web:Thot:Widget:MainWindowProvider:Display',
                },
                canActivate: [ProviderPermissionsGuard],
            },
            {
                path: 'provider-maintenance-request',
                children: [
                    {
                        path: 'board',
                        loadComponent: () =>
                            import(
                                './provider/pages/provider-maintenance-request-board/provider-maintenance-request-board.component'
                            ).then(
                                (x) =>
                                    x.ProviderMaintenanceRequestBoardComponent
                            ),
                        data: {
                            permissions:
                                'UserInterface:Web:Poseidon:Widget:ProviderMaintenanceRequestBoardMainWindow:Display',
                        },
                        canActivate: [ProviderPermissionsGuard],
                    },
                    {
                        path: 'list',
                        loadComponent: () =>
                            import(
                                './provider/pages/provider-maintenance-request-listing/provider-maintenance-request-listing.component'
                            ).then(
                                (x) =>
                                    x.ProviderMaintenanceRequestListingComponent
                            ),
                        data: {
                            permissions:
                                'UserInterface:Web:Poseidon:Widget:ProviderMaintenanceRequestHistoryMainWindow:Display',
                        },
                        canActivate: [ProviderPermissionsGuard],
                    },
                    {
                        path: 'calendar',
                        loadComponent: () =>
                            import(
                                './provider/pages/provider-maintenance-request-calendar/provider-maintenance-request-calendar.component'
                            ).then(
                                (x) =>
                                    x.ProviderMaintenanceRequestCalendarComponent
                            ),
                        data: {
                            permissions:
                                'UserInterface:Web:Poseidon:Widget:ProviderMaintenanceRequestBoardMainWindow:Display',
                        },
                        canActivate: [ProviderPermissionsGuard],
                    },
                ],
            },
            {
                path: 'provider-transport-order',
                children: [
                    {
                        path: 'board-ot',
                        loadComponent: () =>
                            import(
                                './provider/pages/provider-transport-order-board/provider-transport-order-board.component'
                            ).then(
                                (x) => x.ProviderTransportOrderBoardComponent
                            ),
                        data: {
                            permissions:
                                'UserInterface:Web:Poseidon:Widget:ProviderTransportOrderBoardMainWindow:Display',
                        },
                        canActivate: [ProviderPermissionsGuard],
                    },
                    {
                        path: 'calendar',
                        loadComponent: () =>
                            import(
                                './provider/pages/provider-transport-order-calendar/provider-transport-order-calendar.component'
                            ).then(
                                (x) => x.ProviderTransportOrderCalendarComponent
                            ),
                        data: {
                            permissions:
                                'UserInterface:Web:Poseidon:Widget:ProviderTransportOrderCalendarMainWindow:Display',
                        },
                        canActivate: [ProviderPermissionsGuard],
                    },
                ],
            },
        ],
    },
    {
        path: '**',
        redirectTo: 'login',
        pathMatch: 'full',
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
    exports: [RouterModule],
})
export class AppRoutingModule {}
