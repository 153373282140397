import { HttpParams } from '@angular/common/http';
import * as moment from 'moment';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { Vehicle } from 'src/app/models/vehicle';
import { LabelledEntityWithId } from 'src/app/models/labelled-entity-with-id';
import { ModalErrorComponent } from 'src/app/modals/modal-error/modal-error.component';
import {
    AbstractControl,
    FormControl,
    ValidationErrors,
    ValidatorFn,
} from '@angular/forms';
import { VehicleWarehouse } from 'src/app/models/vehicle-warehouse';
import { Tag } from 'src/app/models/tag';
import { TransportOrder } from 'src/app/models/transport-order';
import { Site } from 'src/app/models/site';
import { LabelledEntityWithCode } from 'src/app/models/labelled-entity-with-code';

export default class Utils {
    static vehicle: Vehicle;
    static warehouse: string;
    public static openModalError(
        dialogService: DialogService,
        title: string,
        err: string
    ): void {
        dialogService.open(ModalErrorComponent, {
            data: {
                err: err,
                header: 'Erreur ' + title,
            },
            width: '48rem',
            styleClass: 'dialog-container',
            showHeader: false,
        });
    }

    public static showCreateSuccess(
        messageService: MessageService,
        msg: string,
        title?: string
    ): void {
        messageService.add({
            severity: 'success',
            summary: title ? title : 'Création réussie',
            detail: msg,
        });
    }

    public static showUpdateSuccess(
        messageService: MessageService,
        msg: string
    ): void {
        messageService.add({
            severity: 'info',
            summary: 'Modification réussie',
            detail: msg,
        });
    }

    public static showDeleteSuccess(
        messageService: MessageService,
        msg: string,
        title?: string
    ): void {
        let titleValue: string;
        if (title) {
            titleValue = title;
        } else {
            titleValue = 'Suppression réussie';
        }
        messageService.add({
            severity: 'error',
            summary: titleValue,
            detail: msg,
        });
    }

    //check if registration is new (return true) or old (return false)
    //return null if no match
    public static isNewRegistration(registration: string): boolean | null {
        const regexNew = /[a-zA-Z]{2}-[0-9]{3}-[a-zA-Z]{2}/;
        const regexOld = /[0-9]{1,4}-[a-zA-Z]{2,3}-[0-9]{1,2}/;
        if (regexNew.test(registration) && !regexOld.test(registration)) {
            return true;
        } else {
            if (!regexNew.test(registration) && regexOld.test(registration)) {
                return false;
            } else {
                return null;
            }
        }
    }

    static strictEmailValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (control?.value) {
                const value = control.value;
                const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
                return emailRegex.test(value) ? null : { strictEmail: true };
            } else {
                return null;
            }
        };
    }

    /**
     * @param startDate
     * @param endDate
     * Check if end date is define and if start date is above end date
     * If so, we return the start date in order to ensure coherence for date picking
     * @returns end date
     */
    public static checkDate(startDate: Date, endDate: Date): Date {
        if (endDate && startDate > endDate) {
            return startDate;
        } else {
            return endDate;
        }
    }

    /**
     * @param time stringify time value
     * @returns formatted time HH:MM
     */
    public static formatTime(time: string): string {
        if (time) {
            const splitTime = time.split(':');
            if (splitTime.length > 1) {
                return `${splitTime[0]}:${splitTime[1]}`;
            } else {
                return time;
            }
        } else {
            return null;
        }
    }

    /**
     * @param seconds number
     * @returns formatted time HH:MM
     */
    public static formatSeconds(seconds: number): string {
        const dur = moment.duration(seconds, 'seconds');
        const hours = Math.floor(dur.asHours());
        const mins = Math.floor(dur.asMinutes()) - hours * 60;
        let stringMins: string;
        let stringHours: string;
        if (hours < 10) {
            stringHours = `0${hours}`;
        } else {
            stringHours = hours.toString();
        }
        if (mins < 10) {
            stringMins = `0${mins}`;
        } else {
            stringMins = mins.toString();
        }
        return stringHours + ':' + stringMins;
    }

    // Regexp pour vérifier une heure au format 00:00 (max 23:59)
    /**
     * @params null
     * @returns regex to check time format minutes (between 00:00 and 23:59)
     */
    public static getPatternHourMinute(): RegExp {
        return /^([01]\d|2[0-3]):([0-5]\d)$/;
    }

    /**
     * @params null
     * @returns regex to check time format minutes and seconds (between 00:00:00 and 23:59:59)
     */
    public static getPatternHourMinuteSeconde(): RegExp {
        return /^([01]\d|2[0-3]|23(?=:59?:59?$)):([0-5]\d):([0-5]\d)$/m;
    }

    public static noWhitespaceValidator(control: FormControl) {
        const isWhitespace = (control?.value || '').trim().length === 0;
        const isValid = !isWhitespace;
        return isValid ? null : { whitespace: true };
    }
    /**
     * @params duration (format 00:00 || 00:00:00)
     * @returns number => total of the duration in seconds, if null => returns null
     */
    public static setDurationToNumber(duration: string): number {
        if (duration !== null && duration !== undefined) {
            return moment.duration(duration).asSeconds();
        } else {
            return null;
        }
    }

    /**
     * @params number of seconds
     * @params useSeconds = check if returned format needs to display seconds. (true = 00:00:00, false = 00:00)
     * convert a number to a stringified duration
     * @returns duration with a string format
     */
    public static setNumberToDuration(
        durationNumber: number,
        useSeconds: boolean = false
    ) {
        let hour: string;
        let minute: string;
        let seconds: string;
        if (durationNumber !== null && durationNumber !== undefined) {
            if (moment.duration(durationNumber, 'seconds').hours() > 9) {
                hour = moment
                    .duration(durationNumber, 'seconds')
                    .hours()
                    .toString();
            } else {
                hour = '0' + moment.duration(durationNumber, 'seconds').hours();
            }

            if (moment.duration(durationNumber, 'seconds').minutes() > 9) {
                minute = moment
                    .duration(durationNumber, 'seconds')
                    .minutes()
                    .toString();
            } else {
                minute =
                    '0' + moment.duration(durationNumber, 'seconds').minutes();
            }

            if (useSeconds) {
                if (moment.duration(durationNumber, 'seconds').seconds() > 9) {
                    seconds = moment
                        .duration(durationNumber, 'seconds')
                        .seconds()
                        .toString();
                } else {
                    seconds =
                        '0' +
                        moment.duration(durationNumber, 'seconds').seconds();
                }
                return `${hour}:${minute}:${seconds}`;
            } else {
                return `${hour}:${minute}`;
            }
        } else {
            return null;
        }
    }

    /**
     * @params distance in meters
     * @returns converted value in km with a precision of 2
     */
    public static setDistanceInKilometers(distance: number) {
        // On arrondi ou un affiche les chiffres après la virgule ?
        return Math.round(distance / 1000);
    }

    public static paramsUrlFlexFields(
        params: HttpParams,
        fields: string | string[],
        expand: string | string[],
        omit: string | string[]
    ): HttpParams {
        if (fields) {
            params = params.append(
                'fields',
                Array.isArray(fields) ? fields.toString() : fields
            );
        }
        if (expand) {
            params = params.append(
                'expand',
                Array.isArray(expand) ? expand.toString() : expand
            );
        }
        if (omit) {
            params = params.append(
                'omit',
                Array.isArray(omit) ? omit.toString() : omit
            );
        }
        return params;
    }

    /**
     * @params null
     * @returns List of all available statement sources
     */
    public static getStatementSourceList(): LabelledEntityWithId[] {
        return [
            { id: 'MAN', label: 'Manuelle' },
            { id: 'TEL', label: 'Télématique' },
            { id: 'PMS', label: "Mise à jour d'un élément d'un PMS" },
            { id: 'MTU', label: "Mise à jour d'un ticket de maintenance" },
            { id: 'MTA', label: 'Ticket de maintenance terminé' },
            { id: 'CHK', label: 'Check véhicule' },
            { id: 'OTH', label: 'Autre' },
        ];
    }

    /**
     *
     * @param minutes
     * @returns formatted HHHH:MM
     *
     */
    public static convertMinutesToHoursAndMinutes(minutes: number): string {
        return (
            Math.floor(minutes / 60) +
            ':' +
            (minutes % 60 >= 10
                ? (minutes % 60).toString()
                : '0' + (minutes % 60).toString())
        );
    }

    /**
     * @param hourAndMinute formatted HHHH:MM format
     * @returns number of minutes
     */
    public static convertHoursAndMinutesToMinutes(
        hourAndMinute: string
    ): number {
        const timeArray = hourAndMinute.split(':');
        return +timeArray[0] * 60 + +timeArray[1];
    }

    /**
     * @returns RegExp to verify phone numberd
     */

    public static getphonePattern(): RegExp {
        return /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
    }

    public static setHeaderTitle(vehicle) {
        this.vehicle = vehicle;
    }

    public static setHeaderWarehouse(warehouseDesignation: string) {
        this.warehouse = warehouseDesignation;
    }

    public static getHeaderTitle() {
        return this.vehicle;
    }

    public static getHeaderWarehouse() {
        return this.warehouse;
    }

    public static autocompleteVehicules(
        searchInput: string,
        vehiculeWarehouses: VehicleWarehouse[]
    ): VehicleWarehouse[] {
        if (searchInput == '' || !searchInput) {
            return vehiculeWarehouses;
        }

        let splitedSearch = searchInput.split(' ');
        let filteredVehicules = vehiculeWarehouses;
        splitedSearch.forEach((s) => {
            filteredVehicules = filteredVehicules.filter((e) => {
                if (
                    e.vehicle.registration &&
                    e.vehicle.registration
                        .toLowerCase()
                        .includes(s.toLowerCase())
                ) {
                    return true;
                }
                if (
                    e.vehicle.park_code &&
                    e.vehicle.park_code.toLowerCase().includes(s.toLowerCase())
                ) {
                    return true;
                }
                return false;
            });
        });
        return filteredVehicules;
    }

    /**
     * @param value any
     * @returns true if value not undefined and not null else false
     */
    public static isNotNil(value: any): boolean {
        return value !== undefined && value !== null;
    }

    /**
     * @param date Date value
     * @param format Format expected (must respect moment format syntax)
     *
     * @returns formatted date if date is not null, otherwise returns null
     */
    public static formatDate(date: Date, format: string): string {
        if (date) {
            return moment(date).format(format);
        } else {
            return null;
        }
    }

    /**
     *
     * @param HH:MM:SS
     * @returns HH:MM
     */

    public static convertHoursMinutesSecondsToHoursMinutes(
        value: string
    ): string {
        const seconds = Utils.convertHoursAndMinutesToMinutes(value) * 60;
        return Utils.formatSeconds(seconds);
    }

    public static getAnomalyLabel(label: string): string {
        switch (label) {
            default:
                return 'Non reconnu';
            case '':
                return 'Commence sa journée en retard';
            case '':
                return "Nombre d'heure de travail trop important";
            case 'tour_driver_late':
                return 'Retard début de tournée';
            case '':
                return 'Se connecte ou commence sa journée depuis un lieu inhabituel';
            case '':
                return 'Pas de pause pendant sa journée';
            case '':
                return 'Durée de pause non calculable';
            case '':
                return 'Fin de journée non réalisée';
            case '':
                return 'Planifié présent mais vérifié absent';
            case '':
                return 'Vérifié présent mais planifié absent';
        }
    }

    public static getTagsByType(
        tags: Tag[] | number[],
        typeName: 'merchandise' | 'skill' | 'driver'
    ): Tag[] {
        const arrayTags = [];
        tags.forEach((tag) => {
            if (tag.type.find((t) => t.name === typeName)) {
                arrayTags.push(tag);
            }
        });
        return arrayTags;
    }

    public static getWeekDays(): LabelledEntityWithId[] {
        return [
            { id: '1', label: 'Lundi' },
            { id: '2', label: 'Mardi' },
            { id: '3', label: 'Mercredi' },
            { id: '4', label: 'Jeudi' },
            { id: '5', label: 'Vendredi' },
            { id: '6', label: 'Samedi' },
            { id: '7', label: 'Dimanche' },
        ];
    }

    /**
     * for a given transport order return its transporter designation
     * @param TransportOrder
     * @returns string
     */

    public static getTransporterDesignation(
        transportOrder: TransportOrder
    ): string {
        if (
            this.isNotNil(transportOrder) &&
            this.isNotNil(transportOrder.transporter)
        ) {
            return transportOrder?.transporter.designation;
        } else if (this.isNotNil(transportOrder) && transportOrder?.is_divers) {
            return 'Divers';
        } else {
            return 'Aucun';
        }
    }

    public static getWeekEnd(
        date: Date,
        format: string = 'YYYY-MM-DD'
    ): string {
        return moment()
            .week(+this.getWeekNumber(date))
            .endOf('week')
            .format(format);
    }

    public static getWeekStart(
        date: Date,
        format: string = 'YYYY-MM-DD'
    ): string {
        return moment()
            .week(+this.getWeekNumber(date))
            .startOf('week')
            .format(format);
    }

    public static getWeekNumber(date: Date): number {
        return +moment(date).format('ww');
    }

    public static getMonthLabel(month: number): string {
        switch (month) {
            default:
                return 'Mois non reconnu';
            case 1:
                return 'Janvier';
            case 2:
                return 'Février';
            case 3:
                return 'Mars';
            case 4:
                return 'Avril';
            case 5:
                return 'Mai';
            case 6:
                return 'Juin';
            case 7:
                return 'Juillet';
            case 8:
                return 'Août';
            case 9:
                return 'Septembre';
            case 10:
                return 'Octobre';
            case 11:
                return 'Novembre';
            case 12:
                return 'Décembre';
        }
    }

    public static getLoadingLoadoutSiteDesignation(
        site: Site,
        mode: '0' | '1'
    ): string {
        if (mode === '0') {
            return `${site.zipcode} ${site.city}`;
        } else {
            return site.designation;
        }
    }

    public static fromEnumToObjects(enumerate): LabelledEntityWithCode[] {
        return Object.keys(enumerate).map((key) => ({
            label: enumerate[key],
            code: key,
        }));
    }
}
